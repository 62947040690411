import React from 'react';
import Box from '@webAtoms/Box/Box';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';
import DesignedForToday, {
  DesignedForTodayProps,
} from './Sections/DesignedForToday';
import Report, { ReportProps } from './Sections/Report';
import * as Route from '@routes';
import * as styles from './sustainabilityReport.module.scss';

export interface SustainabilityReportProps {
  title: string;
  designedForToday: DesignedForTodayProps;
  report: ReportProps;
}

const SustainabilityReport: React.FC<SustainabilityReportProps> = ({
  title,
  designedForToday,
  report,
}) => {
  return (
    <Box className={styles.sustainabilityReport}>
      <PageHeading title={title} color="var(--color--white)" />

      <DesignedForToday {...designedForToday} />

      <Report {...report} />

      <UpNext
        links={[
          {
            label: 'Responsibility',
            href: Route.responsibility(),
          },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default SustainabilityReport;
