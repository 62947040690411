import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as styles from './designedForToday.module.scss';

export interface DesignedForTodayProps {
  title: string;
  body: string;
  image?: IGatsbyImageData;
}

const DesignedForToday: React.FC<DesignedForTodayProps> = ({
  title,
  body,
  image,
}) => {
  return (
    <Box className={styles.designedForToday}>
      <Container>
        <Box className={styles.designedForTodaySection}>
          <Box className={styles.designedForTodaySectionImageWrapper}>
            {image && <GatsbyImage loading="lazy" image={image} alt={title} />}
          </Box>
          <Box
            className={styles.designedForTodaySectionContent}
            htmlContent={`<h2>${title}</h2>${body}`}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default DesignedForToday;
