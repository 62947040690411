import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as styles from './report.module.scss';
import Accordion from '@webMolecules/Accordion/Accordion';
import ReadMore from '@webMolecules/ReadMore/ReadMore';
import SvgPetrol from './petrol.inline.svg';
import SvgTree from './tree.inline.svg';
import SvgCoal from './coal.inline.svg';
import SvgBalloon from './balloon.inline.svg';
import SvgCar from './car.inline.svg';
import SvgHouse from './house.inline.svg';
import { render } from '@helpers/markdown';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';

export interface ReportProps {
  sections: ReportSection[];
  sustainabilityPdf: string;
}

export interface ReportSection {
  title: string;
  body: string;
  columns: ReportSectionColumn[];
}

export interface ReportSectionColumn {
  parts: ReportSectionColumnPart[];
}

export interface ReportSectionColumnPart {
  title?: string;
  body: string;
  image?: IGatsbyImageData;
  linkText?: string;
  linkUrl?: string;
  linkToPDF?: boolean;
}

const INFOGRAPHIC_ICONS = [
  ['%TREE', `<img src="${SvgTree}" />`],
  ['%PETROL', `<img src="${SvgPetrol}" />`],
  ['%COAL', `<img src="${SvgCoal}" />`],
  ['%HOUSE', `<img src="${SvgHouse}" />`],
  ['%CAR', `<img src="${SvgCar}" />`],
  ['%BALLOON', `<img src="${SvgBalloon}" />`],
];

const parseBody = (str: string): string =>
  INFOGRAPHIC_ICONS.reduce(
    (acc, [a, b]) => acc.replace(new RegExp(a, 'g'), b),
    render(str)
  );

const Report: React.FC<ReportProps> = ({ sustainabilityPdf, sections }) => {
  return (
    <Box className={styles.report}>
      <Container>
        <Box className={styles.reportInner}>
          {sections.map(section => (
            <Box key={section.title} className={styles.reportSection}>
              <Box className={styles.reportSectionHeading}>
                <h2>{section.title}</h2>
                <Box htmlContent={parseBody(section.body)} />
              </Box>

              <div
                className={styles.reportSectionColumns}
                data-children={section.columns.length}
              >
                {section.columns.map((column, i) => (
                  <Box key={i} className={styles.reportSectionColumn}>
                    {column.parts.map(part =>
                      part.title ? (
                        <Accordion
                          key={part.title}
                          title={part.title}
                          mobileOnly
                          className={styles.reportItemAccordion}
                        >
                          <Box className={styles.reportItemAccordionContent}>
                            <Box htmlContent={parseBody(part.body)} />

                            {part.linkToPDF ? (
                              <ReadMore href={sustainabilityPdf}>
                                {part.linkText || 'Download file'}
                              </ReadMore>
                            ) : part.linkUrl ? (
                              <ReadMore href={part.linkUrl}>
                                {part.linkText || 'Learn more'}
                              </ReadMore>
                            ) : null}

                            {part.image && (
                              <Box
                                className={
                                  styles.reportItemAccordionContentImageWrapper
                                }
                              >
                                <GatsbyImage
                                  loading="lazy"
                                  image={part.image}
                                  alt={part.title ?? ''}
                                />
                              </Box>
                            )}
                          </Box>
                        </Accordion>
                      ) : (
                        <Box
                          key={part.body}
                          className={styles.reportItemAccordionContent}
                        >
                          <Box htmlContent={parseBody(part.body)} />

                          {part.linkUrl && (
                            <ReadMore href={part.linkUrl}>
                              {part.linkText || 'Learn more'}
                            </ReadMore>
                          )}

                          {part.image && (
                            <Box
                              className={
                                styles.reportItemAccordionContentImageWrapper
                              }
                            >
                              <GatsbyImage
                                loading="lazy"
                                image={part.image}
                                alt={part.title ?? ''}
                              />
                            </Box>
                          )}
                        </Box>
                      )
                    )}
                  </Box>
                ))}
              </div>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Report;
